import elpartners from '@images/partners/elpartners.png';
import iwomi from '@images/partners/iwomi.png';
import paribas from '@images/partners/paribas.png';

export const partners = [
  {
    id: 1,
    name: `BNP Paribas`,
    img: paribas,
    slug: `bnp-paribas`,
  },
  {
    id: 2,
    name: `Elpartners`,
    img: elpartners,
    slug: `elpartners`,
  },
  {
    id: 3,
    name: `Iwomi`,
    img: iwomi,
    slug: `iwomi`,
  },
];

export default {};
