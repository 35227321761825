import Container from '@components/Container';
import Layout from '@components/Layout';
import LayoutTopSection from '@components/LayoutTopSection';
import { partners } from '@utils/partners-data.js';
import React from 'react';
import styled from 'styled-components';

const ImagesBox = styled.div`
  text-align: left;

  &:nth-child(2n + 1) {
    text-align: right;
  }
`;

const Partners = () => {
  const renderSiteItem = () => (
    <>
      {partners.map((item) => (
        <ImagesBox key={`${item.slug}-${item.id}`}>
          <img src={item.img} alt={item.name} height="120px" />
        </ImagesBox>
      ))}
    </>
  );

  return (
    <Layout>
      <Container>
        <LayoutTopSection
          showRightSideOnMobile
          title="Partnerzy"
          subtitle="lorem ipsum"
          rightSide={renderSiteItem()}
        />
      </Container>
    </Layout>
  );
};

export default Partners;
